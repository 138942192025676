console.log("version 8/29/24 yogaia");

let _BGV_SETTINGS = window.BGV_SETTINGS = window.BGV_SETTINGS || {}

// PROD
_BGV_SETTINGS.ENV = 'PROD'
_BGV_SETTINGS.PARTNER_ID = '0190bfcc-4cf3-1946-8a17-43761f73c75c'
_BGV_SETTINGS.APP_ID = '0190bfcc-7ff5-ed7e-b2af-6f6834d27424'
_BGV_SETTINGS.PIXEL_ID = '305397045985210'

// ------------------ EDIT for each new flow ----------------------
_BGV_SETTINGS.PAGE_SET_ID = '0190df42-4b7a-8438-8b39-988d4df2f9e5'
// ------------------ EDIT for each new flow ----------------------


_BGV_SETTINGS.renewDisclaimerConf = {
    afterSelectPlanBlock: false,
    afterSubmitButton: true,
};

_BGV_SETTINGS.timerConfig = {
    enable: true,
    buttonText: "Try now",
    countDownSeconds: 60 * 5, // 5 minutes,
    timerDescription: "Discount expires:",
}

_BGV_SETTINGS.lottieConfiguration = {
    // 'lottie': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-08/Animation_-_1716282713663.json',
}



// console.log("version 7/09/24");
// // PROD
// const ENV = 'PROD'
// const PARTNER_ID = '0190bfcc-4cf3-1946-8a17-43761f73c75c'
// const APP_ID = '0190bfcc-7ff5-ed7e-b2af-6f6834d27424'
// const PIXEL_ID = '305397045985210'
// const API_URL = 'https://bgv-backend.getbraavo.com'
// const EVENTS_API_URL = "https://bgv-event-ingestor.getbraavo.com"

// // ------------------ EDIT for each new flow ----------------------
// const PAGE_SET_ID = '0190df42-4b7a-8438-8b39-988d4df2f9e5'
// // ------------------ EDIT for each new flow ----------------------

// // ------------------ START STAGING SETTINGS ----------------------
// // const ENV = 'STAGING'
// // const PARTNER_ID = '018ef7d5-1fb4-dcb2-6877-d60f81c96c89'
// // const APP_ID = '018ef7d5-7982-f637-270a-e0c208acf13a'
// // const PIXEL_ID = '1321355158702713'
// // const API_URL = 'https://bgv-staging-backend.getbraavo.com'
// // const EVENTS_API_URL = "https://bgv-event-ingestor-staging.getbraavo.com"
// // const PAGE_SET_ID = '018fc9f2-ef1f-529c-9b1c-886227a121a9'
// // ------------------ END STAGING SETTINGS ----------------------







// const renewDisclaimerConf = {
//     afterSelectPlanBlock: false,
//     afterSubmitButton: true,
// };

// const timerConfig = {
//     enable: true,
//     buttonText: "Try now",
//     countDownSeconds: 60 * 5, // 5 minutes,
//     timerDescription: "Discount expires:",
// }


// const PageName = Object.freeze({
//     NAME: 'name',
//     REGISTRATION: 'registration',
//     SELECT_PLAN: 'select-plan',
//     PAYMENT_SCREEN: 'payment-screen',
//     VERIFY_CONTACT_INFORMATION:  'verify-contact-information',
//     ALREADY_HAS_ACCOUNT: 'already-has-account',
//     ALREADY_PAID: 'already-paid'
// })

// const REGISTRATION_URL = API_URL + '/api/v1/contact'
// const PLANS_URL = API_URL + '/api/v1/page-set'
// const OPEN_PAGE_SET_URL = API_URL + '/api/v1/open-pageset'

// const EVENTS_URL = EVENTS_API_URL + `/v1/events`
// let planConfig;

// const DEFAULT_PLAN_ID = 'plan_1'
// let isLoading = false

// const tokenKey = 'token'
// const contactIdKey = 'contact_id'
// const sessionIdKey = 'session_id'
// const emailKey = 'email' // TBD: useless
// const tokenHeader = 'x-bgv-token'
// const firstVisitAtKey = 'firstVisitAt'

// const FbEventType = {
//     CONTACT: 'Contact',
//     REGISTRATION: 'Lead',
//     PLAN_SELECTED: 'AddToCart',
// }

// const Intervals = {
//     DAY: 'day',
//     WEEK: 'week',
//     MONTH: 'month',
//     YEAR: 'year',
// }

// // WEB events
// const adParamsToUtmParams = {
//     ad_campaign_id: 'utm_campaign',
//     ad_set_id: 'utm_medium',
//     ad_id: 'utm_content',
//     ad_placement: 'utm_term',
//     ad_source: 'utm_source',
//     email_campaign: 'utm_email_campaign',
// }

// // =================================

// // HF helpers
// function isBuilderMode(){
//     return window.location.origin === 'https://app.heyflow.com'
// }

// function parsePageNameToInternalId() {
//     let pageNameToId = {}
//     const pages = document.querySelectorAll('form section[data-type="user"]')
//     pages.forEach((p) => {
//         const pageName = p.getAttribute('name')
//         pageNameToId[pageName] = p.id

//     })

//     return pageNameToId
// }
// // END HF helpers

// function custom_uuidv4() {
//     return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
//         (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
//     );
// }

// function uuidv4() {
//     try {
//         return crypto.randomUUID();
//     } catch (err) {
//         return custom_uuidv4();
//     }
// }

// const setItem = (key, value) => {
//     if (value === undefined || value === null){
//         return
//     }

//     const storage = window.BGV_STORAGE = window.BGV_STORAGE || {}
//     storage[key] = value

//     return localStorage.setItem(key, value)
// }

// const getItem = (key) => {
//     const storage = window.BGV_STORAGE = window.BGV_STORAGE || {}

//     let value = storage[key]
//     if (value === undefined){
//         value = localStorage.getItem(key)
//         if (value){
//             setItem(key, value)
//         }
//     }

//     return value
// }
// const removeItem = (key) => {
//     localStorage.removeItem(key);
//     delete window.BGV_STORAGE[key];
// }

// const answerPrefix = 'answer__'

// const saveAnswer = (stage, answer) => {
//     const key = answerPrefix + stage
//     setItem(key, answer)
// }

// const getAnswerByStage = (stage) => {
//     const key = answerPrefix + stage
//     return getItem(key) || ''
// }

// function getCookie(cookieName) {
//     let cookie = {};
//     document.cookie.split(";").forEach(function (el) {
//         let [key, value] = el.split("=");
//         cookie[key.trim()] = value;
//     });
//     return cookie[cookieName];
// }

// function setCookie(name, value, options = {}) {
//     options = {
//         path: '/',
//         ...options,
//     }

//     if (options.expires instanceof Date) {
//         options.expires = options.expires.toUTCString()
//     }

//     let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

//     for (const optionKey in options) {
//         updatedCookie += '; ' + optionKey
//         const optionValue = options[optionKey]
//         if (optionValue !== true) {
//             updatedCookie += '=' + optionValue
//         }
//     }

//     document.cookie = updatedCookie
// }


// const UTMParams = [
//     'utm_campaign',
//     'utm_medium',
//     'utm_content',
//     'utm_term',
//     'utm_source',
// ]

// const OtherUrlParams = [
//     'fbclid',
//     'fbc',
//     'fbp',
//     'sid',
//     'fva',
//     'contact_id',
//     'email',
//     'token',
//     'drop_session',
//     'show_storage',
// ]

// const knownParams = [
//     ...UTMParams,
//     ...OtherUrlParams,
// ]

// function getParamsFromQuery() {
//     const paramsFromURL = new URLSearchParams(window.location.search)
//     const params = {}

//     knownParams.forEach((paramName) => {
//         const result = paramsFromURL.get(paramName)
//         if (result) {
//             params[paramName] = result
//         }
//     })

//     return params
// }


// function getElementByXpath(path, screenName) {
//     if (screenName){
//         path = `//section[@name="${screenName}"]` + path
//     }

//     return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
// }

// function sleep(ms) {
//     return new Promise(resolve => setTimeout(resolve, ms));
// }

// function getDurationString(interval, intervalCount, delimiter = ' ') {
//     return `${intervalCount}${delimiter}${interval}${intervalCount > 1 ? 's' : ''}`;
// }

// function getRenewEvery(interval, intervalCount) {
//     if (interval === Intervals.DAY && intervalCount === 1) {
//         return 'daily'
//     }
//     if (intervalCount === 1) {
//         return `${interval}ly`
//     }
//     if (interval === Intervals.MONTH && intervalCount === 3) {
//         return 'quarterly'
//     }
//     if (intervalCount > 1) {
//         return `every ${intervalCount} ${interval}s`
//     }

//     return getDurationString(interval, intervalCount)
// }

// function parsePlan(planData) {
//     return {
//         planInternalId: planData.planInternalId,
//         name: planData.name,
//         crossedPrice: planData.crossedPrice,
//         basePriceAmount: planData.basePriceAmount,
//         trialPrice: planData.trialPriceAmount,
//         crossedPeriodPrice: planData.crossedPeriodPrice,
//         dayPrice: {
//             intPart: planData.periodPrice.split('.')[0],
//             fractionalPart: planData.periodPrice.split('.')[1]
//         },
//         accentPrimary: planData?.accentPrimary,

//         duration: getDurationString(planData.interval, planData.intervalCount),
//         trialDuration: getDurationString(planData.trialInterval, planData.trialIntervalCount),

//         renewEvery: getRenewEvery(planData.interval, planData.intervalCount),
//         renewAfter: getDurationString(planData.trialInterval, planData.trialIntervalCount, '-'),
//         periodLabel: planData.periodLabel,
//         preselected: planData.preselected,
//         tag: planData.tag,
//         title: planData.title,
//         extraDiscountPlan: planData.extraDiscountPlan || false
//     }
// }

// async function fetchPlans() {
//     return await axios.get(PLANS_URL + `/${PAGE_SET_ID}`).then(
//         (response) => {
//             let plans = {};
//             JSON.parse(response.data.plans).sort((a, b) => a.order - b.order).forEach(function (plan, index) {
//                 const planKey = `plan_${(index + 1).toString()}`
//                 const parsedPlanData = parsePlan(plan);

//                 if (parsedPlanData.preselected) {
//                     setItem('defaultPlanId', planKey);
//                 }

//                 if (!parsedPlanData.extraDiscountPlan){
//                     plans[planKey] = parsedPlanData
//                 }
//             })

//             return plans
//         }
//     )
// }


// function extractAdParams() {
//     const result = {}
//     const paramsData = getItem('params');
//     const queryParams = (paramsData && JSON.parse(paramsData)) || {}

//     if (Object.keys(queryParams)) {
//         for (const key in adParamsToUtmParams) {
//             const utmParamName = adParamsToUtmParams[key]
//             const value = queryParams[utmParamName]
//             if (value) {
//                 result[key] = value
//             }
//         }
//     }

//     return result
// }

// const trackInternalAnalyticsEvent = async (eventType, eventData, screenName) => {
//     if (!PAGE_SET_ID) {
//         return
//     }

//     const adData = extractAdParams()
//     if (adData) {
//         eventData = {
//             ...eventData,
//             ...adData,
//         }
//     }
//     console.log('trackInternalAnalytics', eventType, screenName, eventData)
//     const sessionId = getItem(sessionIdKey);
//     const firstVisitAt = getItem(firstVisitAtKey)

//     let millisecondsSinceFirstVisit = 0
//     if (firstVisitAt) {
//         millisecondsSinceFirstVisit = +new Date() - +new Date(firstVisitAt)
//     }

//     let currentUrl = undefined
//     try {
//         currentUrl = window.location.origin + window.location.pathname + window.location.hash
//     } catch {
//     }

//     const headers = {
//         'Content-Type': 'application/json',
//     }

//     const eventId = uuidv4()

//     const token = getItem(tokenKey)

//     if (token && token !== 'undefined' && token !== 'null') {
//         headers[tokenHeader] = token
//     }

//     const data = {
//         ...eventData,
//         event_id: eventId,
//         event: eventType,
//         stage_name: screenName || "UNKNOWN",
//         app_id: APP_ID,
//         page_set_id: PAGE_SET_ID,
//         partner_id: PARTNER_ID,
//         session_id: sessionId,
//         url: currentUrl,
//         referer_url: document.referrer || undefined,
//         milliseconds_since_first_visit: millisecondsSinceFirstVisit,
//     }

//     try {
//         await axios.post(
//             EVENTS_URL,
//             {
//                 events: [
//                     {
//                         type: 'analytics',
//                         data,
//                     },
//                 ],
//             },
//             {
//                 headers,
//             }
//         )
//         console.log(`${eventType} event sent successfully`)
//     } catch (error) {
//         console.log(`Error while sending ${eventType} event`, error)
//     }
// }

// async function trackWebEventSessionStart() {
//     return await trackInternalAnalyticsEvent(
//         'session_start',
//         {
//             stage_values: [],
//             stage_variation: [],
//             plan_id: getItem('internalPlan'),
//         },
//         'landed'
//     )
// }

// async function trackPageView(screenName) {
//     return await trackInternalAnalyticsEvent(
//         'page_view',
//         {
//             stage_values: [],
//             stage_variation: [],
//             plan_id: getItem('internalPlan'),
//         },
//         screenName
//     )
// }

// async function trackPageComplete(screenName) {
//   const answer = getAnswerByStage(screenName);
//   const stageValues = answer.length ? [answer] : []

//   return await trackInternalAnalyticsEvent(
//     'page_complete',
//     {
//       stage_values: stageValues,
//       stage_variation: [],
//     },
//     screenName
//   )
// }

// async function trackRegistration() {
//     return await trackInternalAnalyticsEvent(
//         'registration_success',
//         {
//             stage_values: [],
//             stage_variation: [],
//         },
//         PageName.REGISTRATION
//     )
// }

// async function trackPlanSelected(screenName) {
//     return await trackInternalAnalyticsEvent(
//         'plan_selected',
//         {
//             stage_values: [],
//             stage_variation: [],
//             plan_id: getItem('internalPlan'),
//         },
//         screenName
//     )
// }

// async function trackWebEventPlanClick() {
//     return await trackInternalAnalyticsEvent(
//         'plan_click',
//         {
//             stage_values: [],
//             stage_variation: [],
//             plan_id: getItem('internalPlan'),
//         },
//         PageName.SELECT_PLAN
//     )
// }

// async function trackLogin() {
//     return await trackInternalAnalyticsEvent(
//         'login',
//         {
//             stage_values: [],
//             stage_variation: [],
//             plan_id: getItem('internalPlan'),
//         },
//         PageName.REGISTRATION
//     )
// }

// // FACEBOOK functions
// async function trackFacebookEvent(eventType, eventData) {
//     if (!PAGE_SET_ID) {
//         return
//     }

//     console.log('trackFacebookEvent', eventType, eventData)
//     const sessionId = getItem(sessionIdKey);

//     let currentUrl = undefined;
//     try {
//         currentUrl = window.location.origin + window.location.pathname + window.location.hash
//     } catch {
//     }

//     const headers = {
//         'Content-Type': 'application/json',
//     };

//     const eventId = uuidv4();
//     const data = {
//         ...eventData,
//         external_id: sessionId,
//         event_id: eventId,
//         event_name: eventType,
//         pixel_id: +PIXEL_ID,
//         event_source_url: currentUrl,
//     }

//     try {
//         const fbUserData = JSON.parse(getItem('fb_user_data')) || {}
//         if (fbUserData.fbc) {
//             data.fbc = fbUserData.fbc;
//             data.fbp = getCookie('_fbp') || fbUserData.fbp;
//         }
//     } catch {}

//     // const token = queryParams[tokenKey] || getItem(tokenKey);
//     const token = getItem(tokenKey);
//     if (token && token !== 'undefined' && token !== 'null') {
//         headers['X-BGV-TOKEN'] = token;
//     }

//     try {
//         sendFbqEvent(eventId, eventType)
//     } catch (e) {
//         console.log(e)
//     }

//     try {
//         await axios.post(EVENTS_URL, {events: [{type: "facebook", data}]}, headers)
//         console.log(`${eventType} event sent successfully`)
//     } catch (error) {
//         console.log(`Error while sending ${eventType} event`, error)
//     }
// }

// async function trackFBRegistration() {
//     return await trackFacebookEvent(FbEventType.REGISTRATION, {})
// }

// async function trackAddToCart() {
//     return await trackFacebookEvent(FbEventType.PLAN_SELECTED, {})
// }

// async function trackContact() {
//     return await trackFacebookEvent(FbEventType.CONTACT, {})
// }

// function sendFbqEvent(eventId, eventType) {
//     if (!fbq || !fbq.getState) {
//         return;
//     }

//     // if (ENV !== 'PROD') {
//     //     console.log(`sending FbqEvent is disabled on ${ENV}`)
//     //     return
//     // }

//     const state = fbq.getState();
//     if (!state.pixels.length) {
//         if (PIXEL_ID) {
//             fbq('set', 'autoConfig', 'false', PIXEL_ID)
//             fbq("init", PIXEL_ID, {external_id: getItem(sessionIdKey)});
//             fbq('track', 'PageView');
//         }
//     }

//     if (eventType == FbEventType.REGISTRATION) {
//         return fbq("track", "Lead", undefined, {eventID: eventId});
//     }

//     if (eventType == FbEventType.PLAN_SELECTED) {
//         return fbq("track", "AddToCart", undefined, {eventID: eventId});
//     }
//     if (eventType == FbEventType.CONTACT) {
//         return fbq("track", "Contact", undefined, {eventID: eventId});
//     }
// }

// // -------- START handling url params ---------

// function saveSessionParams(urlParams, updatedParams) {
//     const [firstVisitAt, updated] = saveFirstVisit(urlParams.fva, updatedParams)

//     if (urlParams[contactIdKey]) {
//         if (urlParams[tokenKey]){
//             setItem(tokenKey, urlParams[tokenKey])
//         }
//         setItem(contactIdKey, urlParams[contactIdKey])
//         // setItem(emailKey, urlParams.email)
//     } else {
//         removeItem(tokenKey);
//         removeItem(contactIdKey);
//     }
//     let sessionId;
//     let freshSession = false
//     if (urlParams.sid) {
//         sessionId = urlParams.sid
//         setItem(sessionIdKey, sessionId)

//     } else if (!(sessionId = getItem(sessionIdKey) || undefined)) {
//         sessionId = uuidv4()
//         setItem(sessionIdKey, sessionId)
//         freshSession = true
//     }

//     return [sessionId, firstVisitAt, updated, freshSession]
// }

// function initFBPixel(sessionId, fbParams){
//     // every time we have our own fbc we set cookie
//     // to avoid mismatch between our fbc and the one generated by fb pixel
//     //
//     let domainParts = window.location.hostname.split('.')
//     if (domainParts.length > 2) {
//         domainParts = domainParts.slice(-2)
//     }
//     const domain = '.' + domainParts.join('.')

//     const cookieOptions = {
//         domain,
//         'max-age': 4600 * 24 * 90, // 3 month
//         path: '/',
//         samesite: 'lax',
//     }
//     if (fbParams?.fbc) {
//         setCookie('_fbc', fbParams?.fbc, cookieOptions)
//         setCookie('_fbp', fbParams?.fbp, cookieOptions)
//     } else {
//         cookieOptions['max-age'] = 0 // cookie will be removed
//         setCookie('_fbc', '', cookieOptions)
//         setCookie('_fbp', '', cookieOptions)
//     }

//     if (!fbq || !fbq.getState) {
//         return;
//     }
//     const state = fbq.getState();
//     if (!state.pixels.length) {
//         if (PIXEL_ID) {
//             fbq('set', 'autoConfig', 'false', PIXEL_ID)
//             fbq("init", PIXEL_ID, {external_id: getItem(sessionIdKey)});
//             fbq('track', 'PageView');
//         }
//     }
// }

// function saveFirstVisit(firstVisitAtFromURL, newParams) {
//     let firstVisitAt;
//     const firstVisitAtFromLS = getItem(firstVisitAtKey)

//     if (firstVisitAtFromURL) {
//         const timestamp = Date.parse(firstVisitAtFromURL)
//         if (!isNaN(timestamp)) {
//             firstVisitAt = new Date(timestamp)
//             const isoStr = firstVisitAt.toISOString()
//             setItem(firstVisitAtKey, isoStr)
//             return [firstVisitAt, isoStr !== firstVisitAtFromLS]
//         }
//     }
//     if (!firstVisitAt && firstVisitAtFromLS) {
//         firstVisitAt = new Date(firstVisitAtFromLS)
//     }
//     if (!firstVisitAt || newParams) {
//         firstVisitAt = new Date()
//         setItem(firstVisitAtKey, firstVisitAt.toISOString())
//     }

//     return [firstVisitAt, firstVisitAtFromLS !== firstVisitAt.toISOString()]
// }


// function saveAdNetworkData(queryParams) {
//     let fb_user_data = {}
//     if (queryParams.fbc && queryParams.fbp) {
//         fb_user_data = {
//             fbp: queryParams.fbp,
//             fbc: queryParams.fbc,
//         }

//     } else if (queryParams.fbclid) {
//         const ts = new Date().getTime()
//         fb_user_data = {
//             fbc: `fb.1.${ts}.${queryParams.fbclid}`,
//             fbp: `fb.1.${ts}.${Math.round(2147483648 * Math.random())}`,
//         }
//         addFacebookState(fb_user_data.fbp, fb_user_data.fbc)
//     } else {
//         const fb_str = getItem('fb_user_data')
//         if (fb_str) {
//             fb_user_data = JSON.parse(fb_str)
//         }
//     }

//     if (fb_user_data) {
//         setItem('fb_user_data', JSON.stringify(fb_user_data))
//     }

//     return fb_user_data
// }

// function enrichParamsWithUrlParams(urlParams) {
//     const paramsData = getItem('params') || '{}'
//     const parsedParams = (paramsData && JSON.parse(paramsData)) || {}

//     for (const paramName of UTMParams) {
//         const value = urlParams[paramName]
//         if (!value) {
//             continue
//         }
//         parsedParams[paramName] = value
//     }

//     const newParamsStr = JSON.stringify(parsedParams)
//     setItem('params', newParamsStr)

//     return [parsedParams, newParamsStr !== paramsData]
// }

// function replaceState(
//     urlParams,
//     sessionId,
//     firstVisitAt,
//     updatedFVA,
//     freshSession
// ) {
//     if (isBuilderMode()){
//         return
//     }
//     const currentUrl = new URL(window.location.href)
//     if (
//         freshSession ||
//         urlParams.token ||
//         updatedFVA ||
//         !currentUrl.searchParams.get('sid') ||
//         !currentUrl.searchParams.get('fva')
//     ) {
//         if (urlParams.token || freshSession || !currentUrl.searchParams.get('sid')) {
//             currentUrl.searchParams.set('sid', sessionId)
//         }
//         if (updatedFVA || !currentUrl.searchParams.get('fva')) {
//             currentUrl.searchParams.set('fva', firstVisitAt.toISOString())
//         }
//         window.history.replaceState(
//             window.history.state,
//             '',
//             currentUrl.pathname + currentUrl.search + currentUrl.hash
//         )
//     }
// }

// function addUserState(token, contactId) {
//     if (isBuilderMode()){
//         return
//     }
//     const currentUrl = new URL(window.location.href)

//     if (token) {
//         setItem(tokenKey, token);
//         currentUrl.searchParams.set(tokenKey, token)
//     }
//     if (contactId) {
//         setItem(contactIdKey, contactId);
//         currentUrl.searchParams.set(contactIdKey, contactId)
//     }

//     window.history.replaceState(
//         window.history.state,
//         '',
//         currentUrl.pathname + currentUrl.search + currentUrl.hash
//     )
// }

// function addFacebookState(fbp, fbc){
//     if (isBuilderMode()){
//         return
//     }
//     if (fbp && fbc){
//         const currentUrl = new URL(window.location.href)
//         currentUrl.searchParams.set('fbp', fbp)
//         currentUrl.searchParams.set('fbc', fbc)

//         window.history.replaceState(
//             window.history.state,
//             '',
//             currentUrl.pathname + currentUrl.search + currentUrl.hash
//         )
//     }
// }

// function dropSession() {
//     localStorage.clear()

//     const currentUrl = new URL(window.location.href)

//     currentUrl.searchParams.delete('drop_session')
//     currentUrl.hash = ''

//     window.location.href = currentUrl.href
// }

// function initSessionData() {
//     const queryParams = getParamsFromQuery()
//     if (queryParams.drop_session) {
//         dropSession()
//         return
//     }

//     const fbParams = saveAdNetworkData(queryParams)
//     const [utmParams, updatedParams] = enrichParamsWithUrlParams(queryParams)

//     console.log('url', queryParams, fbParams, utmParams)

//     const [sessionId, fva, updatedFVA, freshSession] = saveSessionParams(
//         queryParams, updatedParams
//     );

//     initFBPixel(sessionId, fbParams)

//     replaceState(queryParams, sessionId, fva, updatedFVA, freshSession)

//     // using for getting storage values in in-app browsers
//     if (queryParams.show_storage) {
//         let info = ''
//         Object.keys(localStorage)
//             .sort()
//             .forEach((key) => {
//                 info += `${key}: ${localStorage[key]}\n`
//             })
//         alert(info)
//     }

//     if (freshSession) {
//         trackWebEventSessionStart();
//     }
// }


// // -------- END handling url params ---------

// // -------- START handling registration workflow -----------
// async function createContact(payload) {
//     return await axios.post(REGISTRATION_URL, payload).then(
//         (response) => {
//             const token = response.headers[tokenHeader]
//             if (token) {
//                 return {
//                     contactId: response.data.contact_id,
//                     token: token,
//                     email: payload.email,
//                 }
//             }
//             return {
//                 contactId: response.data.contact_id,
//                 state: response.data.state,
//             }
//         }
//     )
// }

// async function createUser(email, screenName) {
//     if (!email) {
//         console.log('email is missing');
//         return
//     }

//     const urlParams = getItem('params');
//     const utmParams = urlParams ? JSON.parse(urlParams) : {};

//     const fbData = getItem('fb_user_data') || '';
//     const fbUserData = (fbData && JSON.parse(fbData)) || {}

//     if (fbUserData && fbUserData.fbc && fbUserData.fbp) {
//         utmParams.fbc = fbUserData.fbc;
//         utmParams.fbp = fbUserData.fbp;
//     }

//     const firstVisitAt = getItem(firstVisitAtKey)
//     let millisecondsSinceFirstVisit = 0
//     if (firstVisitAt) {
//         millisecondsSinceFirstVisit = +new Date() - +new Date(firstVisitAt)
//     }

//     const sessionId = getItem(sessionIdKey);
    
//     const firstName = getElementByXpath(`//input[@data-label="input-firstname"]`);
    
//     const payload = {
//         name: firstName && firstName.value,
//         email: email,
//         pixel_id: PIXEL_ID,
//         utm_params: utmParams,
//         page_set_id: PAGE_SET_ID,
//         session_id: sessionId,
//         first_visit_at_diff: millisecondsSinceFirstVisit,
//     };

//     try {
//         return await createContact(payload).then(
//             async function (data) {
//                 // console.log('createContact', data);
//                 let token = data && data.token;
//                 let contactId = data && data.contactId;
//                 if (token) {
//                     setItem(emailKey, data.email);
//                 }
//                 addUserState(token, contactId)

//                 if (data.state === 'verification_code_sent') {
//                     await trackLogin()
//                     isLoading = false;
//                     window.location = window.location.origin + window.location.pathname + window.location.search + `#${PageName.VERIFY_CONTACT_INFORMATION}`
//                     return data

//                 } else if (data.state === 'has_account') {
//                      await trackLogin()
//                     // openPageSetPage(PageName.ALREADY_HAS_ACCOUNT)
//                     // return data
//                 } else if (data.state === 'has_subscription') {
//                      await trackLogin()
//                     // openPageSetPage(PageName.ALREADY_PAID)
//                     // return data
//                 } else {
//                     Promise.all([trackRegistration(), trackFBRegistration()])
//                 }

//                 isLoading = false;
//                 clickOnNextPage(screenName);

//                 return data

//             }).catch(
//             function (error) {
//                 isLoading = false;
//                 const errorMessage = error?.response?.data?.email || 'Please enter a valid email';
//                 displayErrorMessage('\u24D8 ' + errorMessage, screenName);
//                 console.log('error', error)
//                 return false
//             });
//     } catch (error) {
//         console.error('error', error)
//         return false
//     }

// }

// async function verifyEmail(contactId, code) {
//     const payload = {
//         code: code,
//         page_set_id: PAGE_SET_ID,
//     }
//     return await axios.post(API_URL + `/api/v1/contact/${contactId}/verify-email-code`, payload)
// }

// // -------- END handling registration workflow -----------


// function handleChildName(screenName, currentEventName) {
//     const inputElement = getElementByXpath(`//input[@data-label="input-child-name"]`, screenName)
//     if (!inputElement) {
//         return
//     }
//     const defaultChildName = 'You child'
//     if (currentEventName === 'skipEnteringName' || (inputElement.value === '')) {
//         inputElement.value = defaultChildName
//     }
// }

// function openPageSetPage(screenName){
//     const planInternalId = getItem('internalPlan');
//     const urlParams = getParamsFromQuery();
//     const token = urlParams.token || getItem(tokenKey);

//      const queryParams = {
//         app_id: APP_ID,
//         screen_name: screenName,
//         token: token,
//     }
//     if (planInternalId){
//         queryParams['plan_id'] = planInternalId
//     }

//     const paymentScreenQueryParams = new URLSearchParams(queryParams).toString();
//     window.location = OPEN_PAGE_SET_URL + `?${paymentScreenQueryParams}`
// }


// function handleSelectPlanSubmit(screenName) {
//     const fnPromises = [trackPlanSelected(screenName)]

//     const isAddToCardSent = getItem('AddToCardSent')
//     if (isAddToCardSent !== '1') {
//         fnPromises.unshift(trackAddToCart())
//         setItem('AddToCardSent', '1')
//     }

//     return Promise.all(fnPromises).finally(() => {
//         openPageSetPage(PageName.PAYMENT_SCREEN)
//     })
// }

// function clickOnNextPage(screenName) {
//     getElementByXpath(`//button[.//*[contains(text(), 'GoNext')]]`, screenName).click();
// }


// // --------------------- SELECT PLAN --------------------------
// function updateSelectedPlan(e) {
//     if (e.currentTarget.className.includes('selected')) {
//         return
//     }

//     let newPlanId = e.currentTarget.dataset.plan;
//     document.querySelectorAll(".plan-container-v2-wrapper.selected").forEach(opt => {
//         opt.classList.remove('selected');
//     })
//     document.querySelectorAll(`.plan-container-v2-wrapper[data-plan='${newPlanId}']`).forEach((el) => {
//         el.classList.add('selected')
//     })
//     // update renewal subscription text
//     document.querySelectorAll('.subscription-renew').forEach((el) => {
//         el.innerHTML = '';
//         getRenewSubscriptionText(newPlanId).forEach(txt => {
//             let p = document.createElement('p');
//             p.textContent = txt;
//             el.appendChild(p);
//         })
//     });

//     // update input value for redirect param
//     setupInternalPlanIdValue(newPlanId);
//     trackWebEventPlanClick()
// }

// function setupInternalPlanIdValue(planId) {
//     setItem('internalPlan', planConfig[planId].planInternalId);
// }

// function createPlanContainer(planId, planConfig) {
//     // console.log('createPlanContainer', planId, planConfig)
//     let h = preact.h;

//     let titleContainer = undefined
//     if (planConfig.title) {
//         titleContainer = h('div', {className: 'plan-title'}, planConfig.title);
//     }

//     let divTopText = undefined
//     if (planConfig.accentPrimary) {
//         // text like a title in the 'plan box'
//         const spanText = h('span', {className: 'extra-top'}, planConfig.accentPrimary);
//         divTopText = h('div', {className: 'top-text-v1 extra-top-container'}, spanText);
//     }

//     // left container 
//      const planName = h('span', {className: 'plan-name'}, planConfig.name);
//      const crossedPrice = h('span', {className: 'plan-price crossed'}, '$', planConfig.crossedPrice);
    
//     let tagContainer;
//     if (planConfig.tag){
//         const tag = h('span', {className: 'tag'}, [planConfig.tag])
//         tagContainer = h('div', {className: 'tag-container'}, tag)
//     }
    
//     // add empty space between two prices
//     const currentPrice = h('span', {className: 'plan-price'}, ' $' + (planConfig.trialPrice || planConfig.basePriceAmount));
//     const planPriceContainer = h('span', {className: 'plan-price'}, [crossedPrice, currentPrice]);
    
//     // right container
//     const crossedDailyPriceContainer = h('div', {className: 'daily-crossed-price-container plan-price crossed red'}, ['$' ,planConfig.crossedPeriodPrice]);
//     const currencySign = h('span', {className: "currency"}, '$')
//     const intPart = h('span', {className: 'int-part-v1 number integral-part'}, planConfig.dayPrice.intPart)
    
    
//     const fractionalPart = h('span', {className: 'fractional-part number'}, planConfig.dayPrice.fractionalPart)
//     const periodLabel = h('span', {className: 'period-label number'}, planConfig.periodLabel)
//     const columnContainerDiv = h('div', {className: 'column-container period-label-container'}, [fractionalPart, periodLabel])

//     const leftContainer = h('div', {className: 'left-info-container'}, [planName, tagContainer, planPriceContainer])
//     const rightContainer = h('div', {className: 'right-info-container'}, [crossedDailyPriceContainer, currencySign, intPart, columnContainerDiv])
    
//     const divPriceContainer = h('div', {className: 'price-container-v1 plan-container-v2'}, [leftContainer, rightContainer]);
    
//     // main container
//     let mainContainerCssClass = 'plan-container-v2-wrapper';
//       if (planConfig.accentPrimary) {
//         mainContainerCssClass += ' with-title with-extra'
//      }
//     if (planConfig.tag) {
//         mainContainerCssClass += ' with-tag'
//      }

//     const isDefaultPlan = planConfig.preselected
//     if (isDefaultPlan) {
//         setItem('defaultPlanId', planId);
//         mainContainerCssClass += ' selected';
//     }

//     const planContainer = h('div', {
//         'data-plan': planId,
//         className: mainContainerCssClass,
//         onClick: updateSelectedPlan,
//     }, [divTopText, divPriceContainer]
    
//     )

//     const mainContainerWrapper =  h('div', {
//         className: "plan-container-wrapper-v2 plan-container-section",
//     }, [titleContainer, planContainer])

//     return mainContainerWrapper;
// }

// function getRenewSubscriptionText(planId) {
//     let plan = planConfig[planId];

//     if (!plan.trialPrice) {
//         return []
//     }

//     const renewEvery = plan.renewEvery
//     let renewAfter = 'first subscription'
//     let endPart = 'chosen subscription term'

//     if (plan.trialDuration !== plan.duration) {
//       renewAfter = plan.renewAfter + ' trial'
//       endPart = `your trial and will then be continuously renewed on a ${renewEvery} basis`
//     }

//   const disclaimer = `
//       We’ve automatically applied the discount to your ${renewAfter} price.
//       Please note that your subscription will be automatically renewed at the full price of $${plan.basePriceAmount} at the end of
//       ${endPart}.`

//     return [disclaimer]
// }

// async function initSelectPlanSection() {
//     if (!PAGE_SET_ID) {
//         return
//     }

//     planConfig = await fetchPlans();

//     const defaultPlanId = getItem('defaultPlanId') || DEFAULT_PLAN_ID;
//     setupInternalPlanIdValue(defaultPlanId);

//     let selectPlanSection = []

//     Object.keys(planConfig).forEach((planKey) => {
//         selectPlanSection.push(
//             createPlanContainer(planKey, planConfig[planKey])
//         )
//     })

//     let renewInfoText = [];
//     getRenewSubscriptionText(defaultPlanId).forEach((el) => {
//         renewInfoText.push(
//             preact.h('p', null, el))
//     })

//     const subscriptionRenewInfoSection = createSubscriptionRenewInfoSection(defaultPlanId);

//     const selectPlanContainer = preact.h('div', { className: 'select-plan-block-v2' }, [
//         selectPlanSection,
//         renewDisclaimerConf.afterSelectPlanBlock ? subscriptionRenewInfoSection : null,
//     ]);

//     [...Array(5).keys()].forEach((i) => {
//         const element = document.getElementById(`internal-select-plan-${i}`);
//         if (element) {
//             preact.render(selectPlanContainer, element);
//         }
//     });

//     if (renewDisclaimerConf.afterSubmitButton && subscriptionRenewInfoSection) {
//         document.querySelectorAll('.bgv-btn').forEach((button) => {
//             let wrapperDiv = button.nextElementSibling;
//             if (wrapperDiv && wrapperDiv.classList.contains('subscription-renew-wrapper')) {
//                 return;
//             }
//             wrapperDiv = document.createElement('div');
//             wrapperDiv.className = 'subscription-renew-wrapper';
//             button.insertAdjacentElement('afterend', wrapperDiv);
//             preact.render(subscriptionRenewInfoSection, wrapperDiv);
//         });
//     }
// }

// function createSubscriptionRenewInfoSection(defaultPlanId) {
//     const renewInfoText = getRenewSubscriptionText(defaultPlanId).map((el) =>
//         preact.h('p', null, el)
//     );

//     if (renewInfoText.length === 0){
//         return null
//     }

//     return preact.h('div', {className: 'subscription-renew'}, renewInfoText);
// }
// console.log('...render...');

// // animated button

// function initAnimatedButtons(screenName) {
//     const submitButtons = document.querySelectorAll('.bgv-btn')
//     submitButtons.forEach((btn) => {
//         btn.addEventListener('click', (event) => {
//             handleSelectPlanSubmit(screenName)
//         })
//     })
// }

// // START TIMER
// const countDownClock = (number = 300, format = 'seconds') => {
//     number = timerConfig.countDownSeconds || number

//     const d = document;
//     const selectPlanPageNode = d.querySelector(`section[name="${PageName.SELECT_PLAN}"]`);
//     const minutesElement = selectPlanPageNode.querySelector('.minutes');
//     const secondsElement = selectPlanPageNode.querySelector('.seconds');

//     let countdown;
//     convertFormat(format);

//     if (!minutesElement || !secondsElement) {
//         hideTimer()
//         return;
//     }

//     function convertFormat(format) {
//         switch (format) {
//             case 'seconds':
//                 return timer(number);
//             case 'minutes':
//                 return timer(number * 60);
//         }
//     }

//     function timer(seconds) {
//         const now = Date.now();
//         const then = now + seconds * 1000;
//         let isAlmostFinished = false;
//         countdown = setInterval(
//             () => {
//                 const secondsLeft = Math.round((then - Date.now()) / 1000);

//                 if (!isAlmostFinished && secondsLeft <= 60) {
//                     selectPlanPageNode.querySelector('.timer-container')?.classList.add('almost-finished')
//                     isAlmostFinished = true
//                 }

//                 if (secondsLeft < 0) {
//                     clearInterval(countdown);
//                     hideTimer()
//                     return;
//                 }

//                 displayTimeLeft(secondsLeft);

//             },
//             1000);
//     }

//     function displayTimeLeft(seconds) {
//         minutesElement.textContent = Math.floor((seconds % 86400) % 3600 / 60).toString().padStart(2,
//             '0');
//         secondsElement.textContent = seconds % 60 < 10 ? `0${seconds % 60}` : seconds % 60;
//     }

//     function hideTimer() {
//         selectPlanPageNode.querySelector('.timer-container').style.display = "none"
//     }
// }

// function initTimerEventListener() {
//     const timerButton = document.querySelector(`section[name="${PageName.SELECT_PLAN}"] .timer-container .timer-button`);
//     const selectPlanNodes = document.querySelectorAll(`section[name="${PageName.SELECT_PLAN}"] .internal-select-plan`);

//     if (timerButton && selectPlanNodes) {
//         const lastSelectPlanNode = selectPlanNodes[selectPlanNodes.length - 1];
//         if (!lastSelectPlanNode) {
//             return
//         }
//         timerButton.addEventListener("click", function (e) {
//             lastSelectPlanNode.scrollIntoView({
//                 behavior: "smooth"
//             })
//         });
//     }
// }

// function createTimer() {

//     function createEl(type, className, children) {
//         return preact.h(type, className ? {
//             className: className
//         } : {}, children)
//     }

//     function createTimeContainer(timeCssClass, unitStr) {

//         const clockDiv = createEl('div', timeCssClass ? timeCssClass : '', '--')
//         const timeUnitDiv = createEl('div', 'time-unit', unitStr)

//         return createEl('div', 'time', [clockDiv, timeUnitDiv])
//     }


//     const minutesContainer = createTimeContainer('minutes', 'min');
//     const secondsContainer = createTimeContainer('seconds', 'sec');

//     const timerWrapperContainer = createEl('div', 'time-wrapper', [minutesContainer, createEl('span', null, ':'), secondsContainer])

//     const timerDescription = createEl('div', 'timer-description', timerConfig.timerDescription)
//     const innerContainer = createEl('div', 'inner-container', [timerDescription, timerWrapperContainer])

//     const button = createEl('button', 'timer-button', timerConfig.buttonText)
//     const timerButtonContainer = createEl('div', 'timer-button-container', button)

//     const timerContainer = createEl('div', 'timer-container', [innerContainer, timerButtonContainer])
//     const bgvTimerEl = document.getElementById('bgv-timer');
//     if (bgvTimerEl) {
//         preact.render(timerContainer, bgvTimerEl);
//         return true
//     }
//     return false
// }

// let isTimerInitialized = false
// function initTimer() {
//     if (!timerConfig.enable || isTimerInitialized) {
//         return
//     }

//     isTimerInitialized = true;
//     const isTimerSectionCreated = createTimer(); // create timer section
//     if (isTimerSectionCreated){
//         countDownClock(); // start the countdown
//         initTimerEventListener(); // handle CTA clicks
//     }
// }


// // END TIMER

// function displayErrorMessage(message, screenName) {
//     const errorMessageEl = getElementByXpath(`//div[contains(@class, 'bgv-error-message')]`, screenName);
//     if (errorMessageEl) {
//         errorMessageEl.classList.remove('hidden');
//         errorMessageEl.textContent = message;
//     } else {
//         const errorMessageDiv = document.createElement('div');
//         errorMessageDiv.className = 'bgv-error-message';
//         errorMessageDiv.textContent = message;

//         const inputInnerDiv = getElementByXpath(`//div[contains(@class, 'input')]`, screenName);
//         inputInnerDiv && inputInnerDiv.appendChild(errorMessageDiv)
//     }
// }

// async function handleLeadEvent(screenName) {
//     const email = getElementByXpath(`//input[@data-label="input-email-registration"]`, screenName);
//     if (!isLoading && email && email.value && email.validity.valid) {
//         isLoading = true;
//         const userData = await createUser(email.value, screenName);
//     } else {
//         console.log('email is missing', email, isLoading);
//     }
// }

// async function handleVerificationCodeEvent(screenName) {
//     try {
//         const verificationCode = getElementByXpath(`//input[@data-label="verification-code-label"]`, screenName);
//         if (!isLoading && verificationCode && verificationCode.value && verificationCode.validity.valid) {
//             isLoading = true;
//             const contactId = getItem(contactIdKey);
//             const response = await verifyEmail(contactId, verificationCode.value);
//             addUserState(response.headers[tokenHeader], response.data.contact_id);

//             if (response.data.access_state  === 'has_account') {
//                 // openPageSetPage(PageName.ALREADY_HAS_ACCOUNT)
//                 // return
//             } else if (response.data.access_state === 'has_subscription') {
//                 // openPageSetPage(PageName.ALREADY_PAID)
//                 // return
//             }

//             isLoading = false;
//             clickOnNextPage(screenName);
//         }
//     } catch (error) {
//         isLoading = false;
//         const errorMessage = error?.response?.data?.error || 'Something went wrong. Please, try again later';
//         displayErrorMessage('\u24D8 ' + errorMessage, screenName);
//     }
// }

// function isTokenExists(){
//     if (isBuilderMode()){
//         return true
//     }

//     const token = getItem(tokenKey);

//     if (!token || (token === 'undefined' || token === 'null')) {
//         removeItem(tokenKey)
//         window.location = window.location.origin + window.location.pathname + window.location.search + `#${PageName.REGISTRATION}`
//         return false
//     }
//     return true
// }

// window.addEventListener('heyflow-init', (event) => {
//     console.log('heyflow-init event');
//     initSessionData();
// });
// window.addEventListener('heyflow-screen-view', (event) => {
//     console.log('heyflow screen view:', event.detail);

//     if (event.detail.screenIndex === 0) {
//         console.log('track first screen');
//         trackContact();
//     }

//     const screenName = event.detail.screenName;
//     trackPageView(screenName);

//     if (screenName === PageName.SELECT_PLAN) {
//         isTokenExists()

//         initSelectPlanSection();
//         initAnimatedButtons(screenName)
//         if (timerConfig.enable) {
//             initTimer();
//         }
//     }

//     if (['registration', PageName.VERIFY_CONTACT_INFORMATION].includes(screenName) ){
//         isLoading = false
//     }

// });

// window.addEventListener('heyflow-submit', async (event) => {
//     console.log('heyflow submit:', event.detail);

//     // track add to cart before submit
//     const screenName = event.detail.screenName;
//     if (screenName === PageName.SELECT_PLAN) {
//          handleSelectPlanSubmit(screenName)
//     }
// });

// window.addEventListener('heyflow-button-click', async (event) => {
//     console.log('button click event', event.detail)
//     event.preventDefault();
//     event.stopPropagation();

//     const { screenName, customEventName } = event.detail;

//     if (customEventName === 'Lead') {
//         await handleLeadEvent(screenName);
//     }

//     if (screenName === PageName.VERIFY_CONTACT_INFORMATION) {
//         if (customEventName === 'VerificationCode') {
//             await handleVerificationCodeEvent(screenName);
//         }
//     }

//     if (screenName === PageName.NAME && ['skipEnteringName', 'useCustomChildName'].includes(customEventName)) {
//         handleChildName(screenName, customEventName);
//     }
// });

// window.addEventListener('heyflow-screen-leave', (event) => {
//     console.log('heyflow screen leave', event.detail)

//     const screenName = event.detail.screenName
//     trackPageComplete(screenName)
// })

// window.addEventListener('heyflow-input-click', (event) => {
//   console.log('heyflow input click', event)

//   const screenName = event.detail.screenName;
//   const answer = event.detail.customEventLabel;
//   saveAnswer(screenName, answer);
//   // console.log('save answer', screenName, answer)
// })

// window.addEventListener('heyflow-change', (event) => {
//     const screenName = event.detail.screenName;

//     if (screenName === PageName.VERIFY_CONTACT_INFORMATION) {
//         const errorMessageEl = getElementByXpath(`//div[contains(@class, 'bgv-error-message')][not(contains(@class, 'hidden'))]`, PageName.VERIFY_CONTACT_INFORMATION);

//         if (errorMessageEl) {
//             errorMessageEl.classList.add('hidden');
//         }
//     }
// });

